import React, {useEffect, useState} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Selector } from "../../common/Formfilter";
import Divisionbar from "../../common/Divisionbar";
import { useNavigate } from "react-router-dom";
import './Gedep.css'

function Suptbes() {
   
    useEffect(()=>{
       const cleaner = async ()=> {
       await AsyncStorage.removeItem('@formulario')
       }
 
 
       cleaner()
    }, [])


    const [mobra, setMobra] = useState(false);

    const handleMouseEntermobra = (data) => {
        setMobra(true);
         
    };

    const handleMouseLeavemobra = () => {
        setMobra (false);
    };


    const [arcondicionado, setArcondicionado] = useState(false);

    const handleMouseEnterarcondicionado = (data) => {
        setArcondicionado(true);
         
    };

    const handleMouseLeavearcondicionado = () => {
        setArcondicionado (false);
    };

    const Navegacione = useNavigate ()

    const formselector = async (data) =>{
        let a = Selector(data.target.innerText)
       
        await AsyncStorage.setItem("@formulario", JSON.stringify(a)).then(()=>{
            Navegacione("/FORMS"); })
            .catch((err)=> console.log(err))
    }
 
   return (
  
      <div>
            <div >
            <h1>SUPTBES</h1>
            <Divisionbar/>
            <div className="buttons">
            <div className="menu" onMouseEnter={handleMouseEntermobra} onMouseLeave={handleMouseLeavemobra}>
                <button  style={{backgroundImage: "../../../img/doc.png"}} className={`suptbes-toggle ${mobra ? 'open' : ''}`} ></button>
                <label>Supervisão de treinamentos e bem estar social</label>
                <ul className={`main-menu ${mobra ? 'open' : ''}`}>
                    <li><button onClick={formselector}>2º via do Cartão de Auxílio Alimentação - Greencard</button></li>
                    <li><button onClick={formselector}>Auxílio Creche</button></li>
                    <li><button onClick={formselector}>Auxílio Educacional - Inscrição</button></li>
                    <li><button onClick={formselector}>Auxílio Educação Filho(a) - Inscrição</button></li>
                    <li><button onClick={formselector}>Auxílio Educação Filho(a) - Recadastramento Anual</button></li>
                    <li><button onClick={formselector}>Auxílio Educacional - Reembolso Despesas com Livros e Materiais Escolares</button></li>
                    <li><button onClick={formselector}>Auxílio Educacional - Reembolso Mensal</button></li>
                    <li><button onClick={formselector}>Auxílio Funeral - Reembolso Falecimento de Depentente</button></li>
                    <li><button onClick={formselector}>Plano de Saúde - Reembolso Mensal</button></li>
                    <li><button onClick={formselector}>Plano Odontológico - Reembolso Mensal</button></li>
                    <li><button onClick={formselector}>Auxílio Trabalhador e Dependente com Necessidades Especiais - Reembolso mensal </button></li>
                </ul>


            </div>

            </div>
         </div>     
      </div>
   
   );
 }
 
 export default Suptbes;
 