import React from "react";
import MainLayout from "../common/Layout";
import MainTheme from "../common/Maintheme";
import Divisionbar from "../common/Divisionbar";
import "./meusChamados.css"
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";

function Meuschamados() {

    const [arrayteste, setArrayteste] = useState ([{}])
    const currentapiurl =  process.env.REACT_APP_BASE_URL

    useEffect(()=>{
        const getdata = async ()=> {
            //TRATAMENTO de ERROS
            try{
                const value = await AsyncStorage.getItem('@Userchamados') ?? false;

                // var retrieved = await axios.put(`http://10.254.132:3333/api/v1/getable`, {message: JSON.stringify(value)})

                console.log(JSON.parse(value))

                await axios.put(`${currentapiurl}/api/v1/getable`, JSON.parse(value)).then((e)=>{
                    console.log(e)
                   setArrayteste(e.data)
                })

                // console.log(retrieved)
                //  setArrayteste(retrieved.data)

            //ERROS de CORS
            } catch (error) {
                console.error('Erro na requisição:', error);
                if (error.response) {
                    console.error('Dados:', error.response.data);
                    console.error('Status:', error.response.status);
                    console.error('Cabeçalhos:', error.response.headers);
                } else if (error.request) {
                    console.error('Requisição:', error.request);
                } else {
                    console.error('Erro:', error.message);
                }
            }

        }

       getdata()
    }, [])

    // console.log(arrayteste, "1")


    const circunstance = () => {
        console.log("worked well")
    }

    const elements = [
        <div onClick={circunstance} style={{ marginLeft: "10%", marginRight: "10%", }}>
            <h1 style={{ display: "flex", justifyContent: "center" }}>MEUS CHAMADOS</h1>
            <Divisionbar></Divisionbar>
            <div style={{display: "flex", justifyContent: "center"}}>
            <table className="styled-table" >
                <thead>
                    <tr>
                    <th>Chamado</th>
                    <th>Projeto</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Atualizado em</th>
                    </tr>
                </thead>
                <tbody >
                {arrayteste.map((row, index) => {
                    return(
                    <tr   className="active-row" key={index}>
                        <td >{row.Chamado}</td>
                        <td>{row.Projeto}</td>
                        <td>{row.Tipo}</td>
                        <td>{row.Status}</td>
                        <td>{row.Atualizado}</td>

                    </tr>

                )})}
                </tbody>
            </table>
            </div>
        </div>
    ]
    return (
        <div>
            <MainLayout></MainLayout>
            <MainTheme elements={elements}></MainTheme>

        </div>
    )
}

export default Meuschamados
