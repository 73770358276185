export function Selector(data) {

    // Computador 
    if (data === "Computador não funciona") {
        return [{ project: 12, tracker: 3, project_name: "Computador não funciona" }, 
        { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Tombamento do equipamento", tipo: "input", required: true},
        { nome: "Descrição", tipo: "textarea" },
        ]
    }


    if (data === "Instalação de software") {
        return [{ project: 12, tracker: 3, custum_id2: 37, project_name: "Instalação de software" },{ nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Tombamento do equipamento", tipo: "input", required: true},
        { nome: "Software requisitado", tipo: "input", required: false },
        { nome: "Descrição", tipo: "textarea" },
        ]
    }


    if (data === "Erro de software") {
        return [{ project: 12, tracker: 3,  project_name: "Erro de software" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Tombamento do equipamento", tipo: "input", required: true},
        { nome: "Software requisitado", tipo: "input", required: false },
        { nome: "Descrição", tipo: "textarea" },
        ]
    }



    if (data === "Problema com mouse/teclado") {
        return [{ project: 12, tracker: 3,  project_name: "Problema com teclado/mouse" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Tombamento do equipamento", tipo: "input", required: true},
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    // Contracheque 
    if (data === "Resetar senha") {
        return [{ project: 12, tracker: 42, project_name: "Resetar senha" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Cpf", tipo: "input", required: false },
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        ]
    }

    //  Internet
    if (data === "Abrir chamado") {
        return [{ project: 12, tracker: 3,  project_name: "Internet" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    // Email 
    if (data === "Solicitar e-mail funcional") {
        return [{ project: 12, tracker: 43,  project_name: "Solicitar e-mail funcional" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Cpf", tipo: "input", required: false },
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Função", tipo: "input", required: false },
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Email particular", tipo: "input", required: false },

        ]
    }

    if (data === "Solicitar e-mail setorial") {
        return [{ project: 12, tracker: 43,  project_name: "Solicitar e-mail setorial" }, { nome: "Nome do responsavel", tipo: "input", required: false },
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Sigla", tipo: "input", required: false },
        { nome: "Significado da sigla", tipo: "input", required: false },
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "E-mail desejado", tipo: "input", required: false },
        { nome: "Observação", tipo: "textarea" },

        ]
    }

    if (data === "Recuperar senha") {
        return [{ project: 12, tracker: 43,  project_name: "Recuperar senha" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Cpf", tipo: "input", required: false },
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "E-mail particular", tipo: "input", required: false },
        { nome: "Observação", tipo: "textarea" },

        ]
    }

    // Impressora 
    if (data === "Impressora/Scanner não imprime") {
        return [{ project: 12, tracker: 30,  project_name: "Impressora/Scanner não imprime" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Modelo da impressora", tipo: "input", required: false },
        { nome: "Tombamento do equipamento", tipo: "input", required: true},
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    if (data === "Instalar em outro computador") {
        return [{ project: 12, tracker: 30,  project_name: "Instalar em outro computador" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Modelo da impressora", tipo: "input", required: false },
        { nome: "Tombamento do equipamento", tipo: "input", required: true},
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    // Gsan 
    if (data === "Erros") {
        return [{ project: 10, tracker: 43,  project_name: "Erros Gsan" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Qual funcionalidade", tipo: "input", required: false },
        { nome: "Descrição do ocorrido", tipo: "textarea" },
        ]
    }

    if (data === "Solicitação de dados/relatórios") {
        return [{ project: 10, tracker: 35,  project_name: "Solicitação de dados/relatórios" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    // pentaho 
    if (data === "Solicitação de suporte") {
        return [{ project: 17, tracker: 7,  project_name: "Solicitação de suporte" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Tipo de suporte", tipo: "input", required: false },
        { nome: "Onde", tipo: "input", required: false },
        { nome: "Informações adicionais", tipo: "textarea" },
        ]
    }


    if (data === "Solicitação de acesso") {
        return [{ project: 17, tracker: 7,  project_name: "Solicitação de acesso" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Qual funcionalidade", tipo: "input", required: false },
        { nome: "Descrição do ocorrido", tipo: "textarea" },
        ]
    }


    // Redmnine 

    if (data === "Ativação/desativação de usuário(s)") {
        return [{ project: 161, tracker: 85,  project_name: "Ativação/desativação de usuário(s)" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Ativar ou Desativar?", tipo: "input", required: false },
        { nome: "Qual o projeto?", tipo: "input", required: false },
        { nome: "Lista de usuários", tipo: "textarea" },
        ]
    }

    if (data === "Implantação do Redmine") {
        return [{ project: 161, tracker: 85,  project_name: "Implantação do Redmine" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Setor a ser implantado", tipo: "input", required: false },
        { nome: "Descrição breve do motivo da necessidade da implantação do Redmine", tipo: "textarea" },
        ]
    }


    if (data === "Informar erro/modificação") {
        return [{ project: 161, tracker: 85,  project_name: "Informar erro/modificação" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Qual projeto?", tipo: "input", required: false },
        { nome: "Descrição breve do erro ou da modificação solicitada", tipo: "textarea" },
        ]
    }


    if (data === "Treinamento") {
        return [{ project: 161, tracker: 85,  project_name: "Treinamento" }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Local", tipo: "input", required: false },
        { nome: "Data de previsão para o treinamento", tipo: "input", required: false },
        { nome: "Horário previsto", tipo: "input", required: false },
        { nome: "Descrição das necessidades", tipo: "textarea" },
        ]
    }


    // Piramide 

    if (data === "Suporte Pirâmide") {
        return [{ project: 14, tracker: 85,  project_name: "Suporte Pirâmide", images: true }, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Local", tipo: "input", required: false },
        { nome: "Data de previsão para o treinamento", tipo: "input", required: false },
        { nome: "Horário previsto", tipo: "input", required: false },
        { nome: "Anexar imagens", tipo: "file"},
        { nome: "Descrição das necessidades", tipo: "textarea" },
        ]
    }


    if (data === "Criar/Alterar Perfil"){
        return [{ project: 14, tracker: 85,  project_name: "Criar/Alterar Perfil"}, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Serviço", tipo: "select", drop: 'Servico' },
        
        ]
    }

    if (data === "Treinamento"){
        return [{ project: 14, tracker: 85,  project_name: "Treinamento"}, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Local", tipo: "input"},
        { nome: "Data de previsão para o treinamento", tipo: "input", required: false },
        { nome: "Horário previsto", tipo: "input", required: false },
        { nome: "Descrição das necessidades", tipo: "textarea" },
        ]
    }
    

    // Sala Situação 


    if (data === "Chamados Sala Situação"){
        return [{ project: 139, tracker: 85,  project_name: "Chamados Sala Situação"}, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Predio", tipo: "select", drop: "predio" },
        { nome: "Local", tipo: "input"},
        { nome: "Serviço", tipo: "select", drop: "Servico" },
        { nome: "Horário previsto", tipo: "input", required: false },
        { nome: "Descrição das necessidades", tipo: "textarea" },
        ]
    }

    // Intranet 

    if (data === "Reportar falha/erro"){
        return [{ project: 311, tracker: 85,  project_name: "Reportar Problema Intranet"}, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Cpf", tipo: "input" },
        { nome: "Anexar imagens", tipo: "file"},       
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    // Site 

    

    if (data === "Reportar falha/erro no site"){
        return [{ project: 311, tracker: 85,  project_name: "Reportar Problema Site Casal"}, { nome: "Nome completo", tipo: "input", required: true},
        { nome: "Email corporativo", tipo: "input", required: true},
        { nome: "Número da matricula", tipo: "input", required: true},
        { nome: "Telefone", tipo: "input", required: true},
        { nome: "Setor", tipo: "input", required: true},
        { nome: "Cpf", tipo: "input" },
        { nome: "Anexar imagens", tipo: "file"},       
        { nome: "Descrição", tipo: "textarea" },
        ]
    }

    // Outros 
    if (data === "Abir outro tipo"){
        return [{ project: 12, tracker: 3, project_name: "Outro tipo de chamado" }, { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Setor", tipo: "input", required: true},
            { nome: "Predio", tipo: "select", drop: "predio" },
            { nome: "Tombamento do equipamento", tipo: "input", required: true},
            { nome: "Descrição", tipo: "textarea" },
            { nome: "PDF teste", tipo: "documento", path: "../documentsPdf/relatorio (49).pdf"}
            ]
        }




    //GEROC


    //SUPDOC
    if (data === "Solicitações"){
        return [{ project: 98, tracker: 85, project_name: "SUPDOC - Solicitações" }, 
            { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},            
            { nome: "Setor", tipo: "input", required: true},
            { nome: "Predio", tipo: "select", drop: "predio" },
            { nome: "Servicos", tipo: "select", drop: "Servicos Gsan", required: true },
            { nome: "Nome completo para o novo perfil", tipo: "input", required: true},
            { nome: "Email para o novo perfil", tipo: "input", required: true},
            { nome: "CPF para o novo perfil", tipo: "input", required: true},
            { nome: "Data de nascimento para o novo perfil", tipo: "input", required: true},
            { nome: "Nome completo Funcionario", tipo: "input", required: true},
            { nome: "CPF funcionario", tipo: "input", required: true},
            { nome: "Descrição", tipo: "textarea", required: true },
            { nome: "Descrição dos serviços que serão executados pelo funcionario", tipo: "textarea", required: true},
            ]
        }


        if (data === "Funcionalidades GSAN"){
            return [{ project: 98, tracker: 85, project_name: "SUPDOC - Funcionalidades GSAN" }, 
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},            
                { nome: "Predio", tipo: "select", drop: "predio" },
                { nome: "Descrição", tipo: "textarea", required: true },
               
                ]
            }


            if (data === "Regras de negócio"){
                return [{ project: 98, tracker: 85, project_name: "SUPDOC - Regras de negócio" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},            
                    { nome: "Predio", tipo: "select", drop: "predio" },
                    { nome: "Descrição", tipo: "textarea", required: true },
                   
                    ]
                }


                if (data === "Duvidas"){
                    return [{ project: 98, tracker: 85, project_name: "SUPCAD - Duvidas" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Descrição", tipo: "textarea", required: true },
                       
                        ]
                    }
    

    //SUPCAD

    if (data === "Cadastro"){
        return [{ project: 101, tracker: 85, project_name: "SUPCAD - Cadastro" }, 
            { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},            
            { nome: "Predio", tipo: "select", drop: "predio" },
            { nome: "Setor", tipo: "input", required: true},
            { nome: "Descrição", tipo: "textarea", required: true },
           
            ]
        }

        if (data === "Mapas"){
            return [{ project: 101, tracker: 85, project_name: "SUPCAD - Mapas" }, 
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},            
                { nome: "Predio", tipo: "select", drop: "predio" },
                { nome: "Setor", tipo: "input", required: true},
                { nome: "Descrição", tipo: "textarea", required: true },
               
                ]
            }

            if (data === "Relatório de Ligações e Economias"){
                return [{ project: 101, tracker: 85, project_name: "SUPCAD - Relatório de Ligações e Economias" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},            
                    { nome: "Predio", tipo: "select", drop: "predio" },
                    { nome: "Setor", tipo: "input", required: true},
                    { nome: "Descrição", tipo: "textarea", required: true },
                   
                    ]
                }


                if (data === "Solicitação de Correção de Endereço"){
                    return [{ project: 101, tracker: 85, project_name: "SUPCAD - Solicitação de Correção de Endereço" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        { nome: "Descrição", tipo: "textarea", required: true },
                       
                        ]
                    }

    //SUPFAT

    
    if (data === "Cronograma de Faturamento"){
        return [{ project: 99, tracker: 85, project_name: "SUPFAT - Cronograma de Faturamento" }, 
            { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},            
            { nome: "Predio", tipo: "select", drop: "predio" },
            { nome: "Descrição", tipo: "textarea", required: true },
           
            ]
        }

    
        if (data === "Relatório de Volumes e Valores Faturados"){
            return [{ project: 99, tracker: 85, project_name: "SUPFAT - Relatório de Volumes e Valores Faturados" }, 
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},            
                { nome: "Predio", tipo: "select", drop: "predio" },
                { nome: "Setor", tipo: "input", required: true},
                { nome: "Descrição", tipo: "textarea", required: true },
                // { nome: "Anexar imagens", tipo: "file"}
               
                ]
            }

        //SUPALIC

        if (data === "Liquidação"){
            return [{ project: 100, tracker: 85, project_name: "SUPALIC - Liquidação" }, 
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},            
                { nome: "Predio", tipo: "select", drop: "predio" },
                { nome: "Setor", tipo: "input", required: true},
                { nome: "Descrição", tipo: "textarea", required: true },
                { nome: "Anexar imagens", tipo: "file"}
               
                ]
            }


            if (data === "Pagamento em Duplicidade"){
                return [{ project: 100, tracker: 85, project_name: "SUPALIC - Pagamento em Duplicidade" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},            
                    { nome: "Predio", tipo: "select", drop: "predio" },
                    { nome: "Setor", tipo: "input", required: true},
                    { nome: "Descrição", tipo: "textarea", required: true },
                    // { nome: "Anexar imagens", tipo: "file"}
                   
                    ]
                }

                if (data === "Negativação"){
                    return [{ project: 100, tracker: 85, project_name: "SUPALIC - Negativação" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        { nome: "Descrição", tipo: "textarea", required: true },
                        // { nome: "Anexar imagens", tipo: "file"}
                       
                        ]
                    }


                //SUPMIC

                if (data === "Micromedição e Oficina de Hidrômetros"){
                    return [{ project: 102, tracker: 85, project_name: "SUPMIC - Micromedição e Oficina de Hidrômetros" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                        { nome: "Descrição", tipo: "textarea", required: true },
                        // { nome: "Anexar imagens", tipo: "file"}
                       
                        ]
                    }



    // GESEA

              //GESEA CONVENIO 

              if (data === "Solicitação de mão de obra"){
                return [{ project: 236, tracker: 96, project_name: "GESEA - Solicitação de mão de obra" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},            
                    { nome: "Predio", tipo: "select", drop: "predio" },
                    { nome: "Setor", tipo: "input", required: true},
                    { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                    { nome: "Descrição", tipo: "textarea", required: true },
                    // { nome: "Anexar imagens", tipo: "file"}
                   
                    ]
                }

             //ARCONDICIONADO 

             if (data === "Aparelho da companhia"){
                return [{ project: 236, tracker: 94, project_name: "GESEA - Ar condicionado" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},            
                    { nome: "Predio", tipo: "select", drop: "predio" },
                    { nome: "Setor", tipo: "input", required: true},
                    // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                    { nome: "Descrição", tipo: "textarea", required: true },
                    // { nome: "Anexar imagens", tipo: "file"}
                   
                    ]
                }

                //TELEFONE FIXO

                if (data === "Telefone fixo"){
                    return [{ project: 236, tracker: 94, project_name: "SUPSERV - Telefone fixo" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                        { nome: "Descrição", tipo: "textarea", required: true },
                        // { nome: "Anexar imagens", tipo: "file"}
                       
                        ]
                    }


                    if (data === "Telefone móvel"){
                        return [{ project: 236, tracker: 92, project_name: "SUPSERV - Telefone móvel" }, 
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Telefone", tipo: "input", required: true},
                            { nome: "Email corporativo", tipo: "input", required: true},
                            { nome: "Número da matricula", tipo: "input", required: true},            
                            { nome: "Predio", tipo: "select", drop: "predio" },
                            { nome: "Setor", tipo: "input", required: true},
                            // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                            { nome: "Descrição", tipo: "textarea", required: true },
                            // { nome: "Anexar imagens", tipo: "file"}
                           
                            ]
                        }

                //VIGILANCIA
            
                if (data === "Inteligente"){
                    return [{ project: 236, tracker: 93, project_name: "SUPSERV - Vigilancia Inteligente" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                        { nome: "Descrição", tipo: "textarea", required: true },
                        // { nome: "Anexar imagens", tipo: "file"}
                       
                        ]
                    }



                    if (data === "Patrimônial"){
                        return [{ project: 236, tracker: 93, project_name: "SUPSERV - Vigilancia Patrimonial" }, 
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Telefone", tipo: "input", required: true},
                            { nome: "Email corporativo", tipo: "input", required: true},
                            { nome: "Número da matricula", tipo: "input", required: true},            
                            { nome: "Predio", tipo: "select", drop: "predio" },
                            { nome: "Setor", tipo: "input", required: true},
                            // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                            { nome: "Descrição", tipo: "textarea", required: true },
                            // { nome: "Anexar imagens", tipo: "file"}
                           
                            ]
                        }

                        if (data === "Monitoramento"){
                            return [{ project: 236, tracker: 93, project_name: "SUPSERV - Vigilancia Monitoramente" }, 
                                { nome: "Nome completo", tipo: "input", required: true},
                                { nome: "Telefone", tipo: "input", required: true},
                                { nome: "Email corporativo", tipo: "input", required: true},
                                { nome: "Número da matricula", tipo: "input", required: true},            
                                { nome: "Predio", tipo: "select", drop: "predio" },
                                { nome: "Setor", tipo: "input", required: true},
                                // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                                { nome: "Descrição", tipo: "textarea", required: true },
                                // { nome: "Anexar imagens", tipo: "file"}
                               
                                ]
                            }

                //AR CONDICIONADO

                if (data === "Aparelho da ACR"){
                    return [{ project: 236, tracker: 94, project_name: "SUPTRAN - Ar Condicionado da ACR" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        { nome: "Tombamento do equipamento", tipo: "input", required: true},
                        // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                        { nome: "Descrição", tipo: "textarea", required: true },
                        // { nome: "Anexar imagens", tipo: "file"}
                       
                        ]
                    }


                //Combustíveis 

                if (data === "Transferência de cotas"){
                    return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Combustíveis – Transferência de Cotas" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Predio", tipo: "select", drop: "predio" },
                        { nome: "Setor", tipo: "input", required: true},
                        { nome: "Lotação do veículo", tipo: "input"},
                        { nome: "Placa do veículo doador da cota", tipo: "input"},
                        { nome: "Placa do veículo recebedor da cota", tipo: "input"},
                        { nome: "Valor em R$", tipo: "input"},
                        // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                        { nome: "Informações adicionais", tipo: "textarea", required: true },
                        // { nome: "Anexar imagens", tipo: "file"}
                       
                        ]
                    }


                    if (data === "Dificuldade de abastecimento"){
                        return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Dificuldade de abastecimento" }, 
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Telefone", tipo: "input", required: true},
                            { nome: "Email corporativo", tipo: "input", required: true},
                            { nome: "Número da matricula", tipo: "input", required: true},            
                            { nome: "Predio", tipo: "select", drop: "predio" },
                            { nome: "Setor", tipo: "input", required: true},
                            { nome: "Lotação do veículo", tipo: "input"},
                            { nome: "Placa do veículo", tipo: "input"},                            
                            // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                            { nome: "Informações adicionais", tipo: "textarea", required: true },
                            // { nome: "Anexar imagens", tipo: "file"}
                           
                            ]
                        }

                    
                        if (data === "Solicitação de relatório"){
                            return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Solicitação de relatório" }, 
                                { nome: "Nome completo", tipo: "input", required: true},
                                { nome: "Telefone", tipo: "input", required: true},
                                { nome: "Email corporativo", tipo: "input", required: true},
                                { nome: "Número da matricula", tipo: "input", required: true},            
                                { nome: "Predio", tipo: "select", drop: "predio" },
                                // { nome: "Setor", tipo: "input", required: true},
                                { nome: "Lotação do veículo", tipo: "input"},
                                // { nome: "Placa do veículo", tipo: "input"},                            
                                // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                                { nome: "Informações adicionais", tipo: "textarea", required: true },
                                // { nome: "Anexar imagens", tipo: "file"}
                               
                                ]
                            }
                        
                            if (data === "Outros (Combustíveis)"){
                                return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Outros" }, 
                                    { nome: "Nome completo", tipo: "input", required: true},
                                    { nome: "Telefone", tipo: "input", required: true},
                                    { nome: "Email corporativo", tipo: "input", required: true},
                                    { nome: "Número da matricula", tipo: "input", required: true},            
                                    { nome: "Predio", tipo: "select", drop: "predio" },
                                    // { nome: "Setor", tipo: "input", required: true},
                                    { nome: "Lotação do veículo", tipo: "input"},
                                    // { nome: "Placa do veículo", tipo: "input"},                            
                                    // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                                    { nome: "Informações adicionais", tipo: "textarea", required: true },
                                    // { nome: "Anexar imagens", tipo: "file"}
                                   
                                    ]
                                }
                            
                            
                                if (data === "Pedido de manutenção"){
                                    return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Outros" }, 
                                        { nome: "Nome completo", tipo: "input", required: true},
                                        { nome: "Telefone", tipo: "input", required: true},
                                        { nome: "Email corporativo", tipo: "input", required: true},
                                        { nome: "Número da matricula", tipo: "input", required: true},            
                                        { nome: "Predio", tipo: "select", drop: "predio" },
                                        // { nome: "Setor", tipo: "input", required: true},
                                        { nome: "Lotação do veículo", tipo: "input"},
                                        { nome: "Placa do veículo", tipo: "input"},    
                                        { nome: "Modelo do veículo", tipo: "input"},  
                                        { nome: "Kilometragem do veículo", tipo: "input"},   
                                        { nome: "Predio", tipo: "select", drop: "manutencao veiculos" },
                                        // { nome: "Placa do veículo", tipo: "input"},                            
                                        // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                                        { nome: "Informações adicionais", tipo: "textarea", required: true },
                                        // { nome: "Anexar imagens", tipo: "file"}
                                       
                                        ]
                                    }
                                
                                    if (data === "Informação de sinistro"){
                                        return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Informação de sinistro" }, 
                                            { nome: "Nome completo", tipo: "input", required: true},
                                            { nome: "Telefone", tipo: "input", required: true},
                                            { nome: "Email corporativo", tipo: "input", required: true},
                                            { nome: "Número da matricula", tipo: "input", required: true},            
                                            { nome: "Predio", tipo: "select", drop: "predio" },
                                            // { nome: "Setor", tipo: "input", required: true},
                                            { nome: "Lotação do veículo", tipo: "input"},
                                            { nome: "Placa do veículo", tipo: "input"},    
                                            { nome: "Modelo do veículo", tipo: "input"},  
                                            { nome: "Kilometragem do veículo", tipo: "input"},   
                                            { nome: "Predio", tipo: "select", drop: "manutencao veiculos" },
                                            { nome: "Data do sinistro", tipo: "input" },
                                            // { nome: "Placa do veículo", tipo: "input"},                            
                                            // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                                            { nome: "Informações adicionais", tipo: "textarea", required: true },
                                            // { nome: "Anexar imagens", tipo: "file"}
                                           
                                            ]
                                        }
                                        if (data === "Pedido de veículos para saída a serviço"){
                                            return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Pedido de veículos para saída a serviço" }, 
                                                { nome: "Nome completo", tipo: "input", required: true},
                                                { nome: "Telefone", tipo: "input", required: true},
                                                { nome: "Email corporativo", tipo: "input", required: true},
                                                { nome: "Número da matricula", tipo: "input", required: true},            
                                                { nome: "Predio", tipo: "select", drop: "predio" },
                                                // { nome: "Setor", tipo: "input", required: true},
                                                { nome: "Local da saída do veículo", tipo: "input"},
                                                // { nome: "Placa do veículo", tipo: "input"},    
                                                { nome: "Destino", tipo: "input"},  
                                                // { nome: "Kilometragem do veículo", tipo: "input"},   
                                                { nome: "Predio", tipo: "select", drop: "manutencao veiculos" },
                                                { nome: "Data da saída", tipo: "input" },
                                                // { nome: "Placa do veículo", tipo: "input"},                            
                                                // { nome: "Servicos", tipo: "select", drop: "Servicos GESEA", required: true },
                                                { nome: "Informações adicionais", tipo: "textarea", required: true },
                                                // { nome: "Anexar imagens", tipo: "file"}
                                               
                                                ]
                                            }
                                            if (data === "Outros (Veículos)"){
                                                return [{ project: 231, tracker: 85, project_name: "SUPTRAN - Outros (Veículos)" }, 
                                                    { nome: "Nome completo", tipo: "input", required: true},
                                                    { nome: "Telefone", tipo: "input", required: true},
                                                    { nome: "Email corporativo", tipo: "input", required: true},
                                                    { nome: "Número da matricula", tipo: "input", required: true},            
                                                    { nome: "Predio", tipo: "select", drop: "predio" },
                                                    { nome: "Setor", tipo: "input", required: true},
                                                    { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                                                    { nome: "Descrição", tipo: "textarea", required: true },
                                                    // { nome: "Anexar imagens", tipo: "file"}
                                                   
                                                    ]
                                                }

        //RH
        
        if (data === "Cessão Temporária ou Transferência de Empregado"){
            return [{ project: 31, tracker: 123, project_name: "GEDEP - Cessão Temporária ou Transferência de Empregado" }, 
                { nome: "", tipo: "holder", text: "Dados do empregado"},  
                { nome: "Nome completo do empregado", tipo: "input", required: true},
                { nome: "Número da matricula do empregado", tipo: "input", required: true},  
                { nome: "Cargo", tipo: "input", required: true},
                { nome: "Unidade de origem - Centro de custos", tipo: "input", required: true},  
                { nome: "Unidade de destino - Centro de custos", tipo: "input", required: true},  
                { nome: "", tipo: "holder", text: "No caso de operadores"},  
                { nome: "Unidade operacional", tipo: "input"},  
                { nome: "Estação", tipo: "select", drop: "estacao" },
                { nome: "", tipo: "holder", text: "Solicitante da CTTE"},  

                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},  
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},                             
                { nome: "Predio", tipo: "select", drop: "predio" },
               
                // { nome: "Anexar imagens", tipo: "file"}
                
                ]
            }


            if (data === "O.S. Função Gratificada - Designar (Provisória)"){
                return [{ project: 31, tracker: 126, project_name: "GEDEP - O.S. Função Gratificada - Designar (Provisória)" }, 
                    { nome: "", tipo: "holder", text: "Dados do empregado"},  
                    { nome: "Nome completo do empregado", tipo: "input", required: true},
                    { nome: "Número da matricula do empregado", tipo: "input", required: true},  
                    { nome: "Função gratificada", tipo: "input", required: true},
                    { nome: "", tipo: "holder", text: "Dados do empregado substituído"},  
                    { nome: "Nome completo do empregado", tipo: "input", required: true},
                    { nome: "Número da matricula do empregado", tipo: "input", required: true},  
                    { nome: "Função gratificada", tipo: "input", required: true},

                    { nome: "Data de Inicio", tipo: "input", required: true},  
                    { nome: "Data do fim - Centro de custos", tipo: "input", required: true},  
                    { nome: "", tipo: "holder", text: "Solicitante da OS"},  
                    
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},  
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},                             
                    { nome: "Predio", tipo: "select", drop: "predio" },
                   
                    // { nome: "Anexar imagens", tipo: "file"}
                    
                    ]
                }


                if (data === "O.S. Função Gratificada - Designar (Definitiva)"){
                    return [{ project: 31, tracker: 126, project_name: "GEDEP - O.S. Função Gratificada - Designar (Definitiva)" }, 
                        { nome: "", tipo: "holder", text: "Dados do empregado"},  
                        { nome: "Nome completo do empregado", tipo: "input", required: true},
                        { nome: "Número da matricula do empregado", tipo: "input", required: true},  
                        { nome: "Função gratificada", tipo: "input", required: true},
                        { nome: "", tipo: "holder", text: "Dados do empregado substituído"},  
                        { nome: "Nome completo do empregado", tipo: "input"},
                        { nome: "Número da matricula do empregado", tipo: "input"},  
                        // { nome: "Função gratificada", tipo: "input", required: true},
    
                        { nome: "Data de Inicio", tipo: "input"},  
                        // { nome: "Data do fim - Centro de custos", tipo: "input", required: true},  
                        { nome: "", tipo: "holder", text: "Solicitante da OS"},  
                        
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},  
                        { nome: "Email corporativo", tipo: "input", required: true},
                        { nome: "Telefone", tipo: "input", required: true},                             
                        { nome: "Predio", tipo: "select", drop: "predio" },
                       
                        // { nome: "Anexar imagens", tipo: "file"}
                        
                        ]
                    }
                    
                    if (data === "O.S. Função Gratificada - Revogar (Definitiva)"){
                        return [{ project: 31, tracker: 126, project_name: "GEDEP - O.S. Função Gratificada - Designar (Definitiva)" }, 
                            { nome: "", tipo: "holder", text: "Dados do empregado"},  
                            { nome: "Nome completo do empregado", tipo: "input", required: true},
                            { nome: "Número da matricula do empregado", tipo: "input", required: true},  
                            { nome: "Data de Inicio", tipo: "input"}, 
                            // { nome: "Função gratificada", tipo: "input", required: true},
                            { nome: "", tipo: "holder", text: "Dados do empregado que receberá a função gratificada"},  
                            { nome: "Nome completo do empregado", tipo: "input"},
                            { nome: "Número da matricula do empregado", tipo: "input"},  
                            { nome: "Função gratificada", tipo: "input"},
        
                            // { nome: "Data de Inicio", tipo: "input"},  
                            // { nome: "Data do fim - Centro de custos", tipo: "input", required: true},  
                            { nome: "", tipo: "holder", text: "Solicitante da OS"},  
                            
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Número da matricula", tipo: "input", required: true},  
                            { nome: "Email corporativo", tipo: "input", required: true},
                            { nome: "Telefone", tipo: "input", required: true},                             
                            { nome: "Predio", tipo: "select", drop: "predio" },
                           
                            // { nome: "Anexar imagens", tipo: "file"}
                            
                            ]
                        }


    if (data === "2º via do Cartão de Auxílio Alimentação - Greencard"){
        return [{ project: 282, tracker: 185, project_name: "SUPTBES - 2º via do Cartão de Auxílio" }, 
            { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},            
            { nome: "Lotação", tipo: "select", drop: "lotacao" },
            // { nome: "Setor", tipo: "input", required: true},
            // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
            // { nome: "Descrição", tipo: "textarea", required: true },
            { nome: "Documento de identificação pessoal com foto", tipo: "file"},
            { nome: "Declaro, sob as penas da Lei, que os documentos aqui apresentados digitalizados são verdadeiros e conferem com os documentos originais.", tipo: "columncheckbox", required: true },
            { nome: "Declaro ter ciência que a partir do momento que solicito a segunda via do cartão alimentação Greencard, o cartão atual será bloqueado automaticamente..", tipo: "columncheckbox", required: true }
            
            ]
        }

    
        if (data === "Auxílio Creche"){
            return [{ project: 282, tracker: 180, project_name: "SUPTBES - Auxílio Creche" }, 
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},            
                { nome: "Lotação", tipo: "select", drop: "lotacao" },
                { nome: "Dependente", tipo: "input", required: true},    
                { nome: "Data de nascimento", tipo: "input", required: true},    
                // { nome: "Setor", tipo: "input", required: true},
                // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                // { nome: "Descrição", tipo: "textarea", required: true },
                { nome: "Certidão de nacimento ou termo de guarda ou tutela e suas eventuais renovações:", tipo: "file", required: true},
                { nome: "Declaro, sob as penas da Lei, que os documentos aqui apresentados digitalizados são verdadeiros e conferem com os documentos originais.", tipo: "columncheckbox", required: true }
                
                ]
            }

            if (data === "Auxílio Educacional - Inscrição"){
                return [{ project: 282, tracker: 191, project_name: "SUPTBES - Auxílio Educacional - Inscrição" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},            
                    { nome: "Lotação", tipo: "select", drop: "lotacao" },
                    { nome: "Email corporativo", tipo: "input", required: true},       
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Curso", tipo: "input", required: true},
                    { nome: "Nível do curso", tipo: "select", drop: "nivelacademico", required: true },

                    { nome: "Valor da mensalidade", tipo: "input", required: true},    
                    // { nome: "Data de nascimento", tipo: "input", required: true},    
                    // { nome: "Setor", tipo: "input", required: true},
                    // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                    // { nome: "Descrição", tipo: "textarea", required: true },
                    { nome: "Documento de identificação", tipo: "file", required: true},
                    { nome: "Comprovante de vínculo", tipo: "file", required: true},
                    { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                    { nome: "", tipo:"simpletext", text:"1. Documento de identificação pessoal com foto: RG ou CTPS ou Carteira de habilitação. (obrigatório)"},
                    { nome: "", tipo:"simpletext", text:"2. Comprovante de vínculo com a instituição de ensino. (obrigatório)"}
                    ]
                }


                if (data === "Auxílio Educação Filho(a) - Inscrição"){
                    return [{ project: 282, tracker: 181, project_name: "SUPTBES - Auxílio Educação Filho(a) - Inscrição" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Número da matricula", tipo: "input", required: true},            
                        { nome: "Lotação", tipo: "select", drop: "lotacao" },
                        { nome: "Email corporativo", tipo: "input", required: true},       
                        { nome: "Telefone", tipo: "input", required: true},
                        { nome: "Curso", tipo: "input", required: true},
                        // { nome: "Nível do curso", tipo: "select", drop: "nivelacademico", required: true },
    
                        { nome: "Dependente", tipo: "input", required: true},    
                        { nome: "Data de nascimento", tipo: "input", required: true},    
                        // { nome: "Setor", tipo: "input", required: true},
                        // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                        // { nome: "Descrição", tipo: "textarea", required: true },
                        // { nome: "Certidão de nascimento ou termo de guarda ou tutela e suas eventuais renovações", tipo: "file", required: true},
                        { nome: "Declaração de matrícula emitida pela Instituição de Ensino, constando o valor da mensalidade ou contrato correspondente", tipo: "file", required: true},
                        { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                        { nome: "", tipo:"simpletext", text:"Declaração de matrícula emitida pela Instituição de Ensino, constando o valor da mensalidade ou contrato correspondente."},
                        // { nome: "", tipo:"simpletext", text:"2. Comprovante de vínculo com a instituição de ensino. (obrigatório)"}
                        ]
                    }

                    if (data === "Auxílio Educação Filho(a) - Recadastramento Anual"){
                        return [{ project: 282, tracker: 182, project_name: "SUPTBES - Auxílio Educação Filho(a) - Recadastramento Anual" }, 
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Número da matricula", tipo: "input", required: true},            
                            { nome: "Lotação", tipo: "select", drop: "lotacao" },
                            { nome: "Email corporativo", tipo: "input", required: true},       
                            { nome: "Telefone", tipo: "input", required: true},
                            { nome: "dependente", tipo: "input", required: true},
                            { nome: "Nível do curso", tipo: "select", drop: "nivelacademico", required: true },
        
                            { nome: "Dependente", tipo: "input", required: true},    
                            { nome: "Data de nascimento", tipo: "input", required: true},    
                            // { nome: "Setor", tipo: "input", required: true},
                            // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                            // { nome: "Descrição", tipo: "textarea", required: true },
                            { nome: "Certidão de nascimento ou termo de guarda ou tutela e suas eventuais renovações", tipo: "file", required: true},
                            { nome: "Declaração de matrícula emitida pela Instituição de Ensino, constando o valor da mensalidade ou contrato correspondente", tipo: "file", required: true},
                            { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                            { nome: "", tipo:"simpletext", text:"1. Documento de identificação pessoal com foto: RG ou CTPS ou Carteira de habilitação. (obrigatório)"},
                            { nome: "", tipo:"simpletext", text:"2. Comprovante de vínculo com a instituição de ensino. (obrigatório)"}
                            ]
                        }

                        if (data === "Auxílio Educacional - Reembolso Despesas com Livros e Materiais Escolares"){
                            return [{ project: 282, tracker: 184, project_name: "SUPTBES - Auxílio Educacional - Reembolso Despesas com Livros e Materiais Escolares" }, 
                                { nome: "Nome completo", tipo: "input", required: true},
                                { nome: "Número da matricula", tipo: "input", required: true},            
                                { nome: "Lotação", tipo: "select", drop: "lotacao" },
                                { nome: "Email corporativo", tipo: "input", required: true},       
                                { nome: "Telefone", tipo: "input", required: true},
                                { nome: "Mês referência para reembolso", tipo: "input", required: true},
                                // { nome: "Nível do curso", tipo: "select", drop: "nivelacademico", required: true },
            
                                // { nome: "Dependente", tipo: "input", required: true},    
                                // { nome: "Data de nascimento", tipo: "input", required: true},    
                                // { nome: "Setor", tipo: "input", required: true},
                                // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                                // { nome: "Descrição", tipo: "textarea", required: true },
                                { nome: "Comprovante de vínculo com Instituição de Ensino Pública, ou bolsista integral no ensino privado:", tipo: "file", required: true},
                                { nome: "Nota Fiscal das despesas:", tipo: "file", required: true},
                                { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                                { nome: "", tipo:"simpletext", text:"comprovação de vínculo com Instituição de Ensino Pública ou bolsista integral no ensino privado. Nota Fiscal das despesas."}
                                
                                ]
                            }

                            if (data === "Auxílio Educacional - Reembolso Mensal"){
                                return [{ project: 282, tracker: 183, project_name: "SUPTBES - Auxílio Educacional - Reembolso Mensal" }, 
                                    { nome: "Nome completo", tipo: "input", required: true},
                                    { nome: "Número da matricula", tipo: "input", required: true},            
                                    { nome: "Lotação", tipo: "select", drop: "lotacao" },
                                    { nome: "Email corporativo", tipo: "input", required: true},       
                                    { nome: "Telefone", tipo: "input", required: true},
                                    { nome: "Nível do curso", tipo: "select", drop: "nivelacademico", required: true },
                                    { nome: "Mês referência para reembolso", tipo: "input", required: true},                                 
                
                                    // { nome: "Dependente", tipo: "input", required: true},    
                                    // { nome: "Data de nascimento", tipo: "input", required: true},    
                                    // { nome: "Setor", tipo: "input", required: true},
                                    // { nome: "Servicos", tipo: "select", drop: "Servicos SUPMIC", required: true },
                                    // { nome: "Descrição", tipo: "textarea", required: true },
                                    { nome: "Comprovante de vínculo com Instituição de Ensino Pública, ou bolsista integral no ensino privado:", tipo: "file", required: true},
                                    { nome: "Nota Fiscal das despesas:", tipo: "file", required: true},
                                    { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                                    { nome: "", tipo:"simpletext", text:"Boleto e comprovante de pagamento"},
                                    { nome: "", tipo:"columncheckbox", text:"Declaro, sob as penas da Lei, que os documentos aqui apresentados digitalizados são verdadeiros e conferem com os documentos originais."},
                                    
                                    
                                    
                                    ]
                                }

                                if (data === "Auxílio Funeral - Reembolso Falecimento de Depentente"){
                                    return [{ project: 282, tracker: 186, project_name: "SUPTBES - Auxílio Funeral – Reembolso Falecimento de Dependente" }, 
                                        { nome: "Nome completo", tipo: "input", required: true},
                                        { nome: "Número da matricula", tipo: "input", required: true},            
                                        { nome: "Lotação", tipo: "select", drop: "lotacao" },
                                        { nome: "Email corporativo", tipo: "input", required: true},       
                                        { nome: "Telefone", tipo: "input", required: true},                                        
                                        { nome: "Nome completo do(a) dependente falecido(a)", tipo: "input", required: true},                                 
                    
                                        // { nome: "Dependente", tipo: "input", required: true},    
                                        { nome: "Data de falescimento", tipo: "input", required: true},                                          
                                        { nome: "Documento de identificação pessoal com foto:", tipo: "file", required: true},
                                        { nome: "Certidão de Óbito:", tipo: "file", required: true},
                                        { nome: "Documento comprobatória de parentesco:", tipo: "file", required: true},
                                        { nome: "Documentação comprobatória das despesas com o funeral em nome do empregado solicitante (nota fiscal):", tipo: "file", required: true},
                                       
                                        { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                                        { nome: "", tipo:"simpletext", text:" 1. Documento de identificação pessoal com foto: RG ou CTPS ou Carteira de habilitação"},
                                        { nome: "", tipo:"simpletext", text:" 2. Certidão de Óbito;"},
                                        { nome: "", tipo:"simpletext", text:" 3. Documentação comprobatória de parentesco (certidão de casamento atualizada, união estável, certidão de nascimento para filhos, etc)"},
                                        { nome: "", tipo:"simpletext", text:" 4. Documentação comprobatória das despesas com o funeral em nome do empregado solicitante (nota fiscal)."},
                                                                      
                                        ]
                                    }

                                    if (data === "Plano de Saúde - Reembolso Mensal"){
                                        return [{ project: 282, tracker: 178, project_name: "SUPTBES - Plano de Saúde - Reembolso Mensal" }, 
                                            { nome: "Nome completo", tipo: "input", required: true},
                                            { nome: "Número da matricula", tipo: "input", required: true},            
                                            { nome: "Lotação", tipo: "select", drop: "lotacao" },
                                            { nome: "Email corporativo", tipo: "input", required: true},       
                                            { nome: "Telefone", tipo: "input", required: true}, 
                                            { nome: "Mês referência para reembolso", tipo: "select", drop: "referencias" },                                                                    
                                            { nome: "Boleto:", tipo: "file", required: true},
                                            { nome: "Comprovante de pagamento recibo da AREC ou do Plano de Saúde:", tipo: "file", required: true},
                                            { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                                            { nome: "", tipo:"simpletext", text:" 1.  boleto e comprovante de pagamento ou recibo da AREC ou do Plano de Saúde"},  
                                            ]
                                        }


                                        if (data === "Plano Odontológico - Reembolso Mensal"){
                                            return [{ project: 282, tracker: 179, project_name: "SUPTBES - Plano Odontológico - Reembolso Mensal" }, 
                                                { nome: "Nome completo", tipo: "input", required: true},
                                                { nome: "Número da matricula", tipo: "input", required: true},            
                                                { nome: "Lotação", tipo: "select", drop: "lotacao" },
                                                { nome: "Email corporativo", tipo: "input", required: true},       
                                                { nome: "Telefone", tipo: "input", required: true}, 
                                                { nome: "Mês referência para reembolso", tipo: "select", drop: "referencias" },                                                                    
                                                { nome: "Boleto:", tipo: "file", required: true},
                                                { nome: "Comprovante de pagamento recibo da AREC ou do Plano de Saúde:", tipo: "file", required: true},
                                                { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                                                { nome: "", tipo:"simpletext", text:" 1.  boleto e comprovante de pagamento ou recibo da AREC ou do Plano de Saúde"},  
                                                ]
                                            }


                                            if (data === "Auxílio Trabalhador e Dependente com Necessidades Especiais - Reembolso mensal"){
                                                return [{ project: 282, tracker: 190, project_name: "SUPTBES - Auxílio Trabalhador e Dependente com Necessidades Especiais – Reembolso mensal" }, 
                                                    { nome: "Nome completo", tipo: "input", required: true},
                                                    { nome: "Número da matricula", tipo: "input", required: true},            
                                                    { nome: "Dependente", tipo: "input", required: true},  
                                                    { nome: "Lotação", tipo: "select", drop: "lotacao" },
                                                    { nome: "Email corporativo", tipo: "input", required: true},       
                                                    { nome: "Telefone", tipo: "input", required: true}, 
                                                    { nome: "Mês referência para reembolso", tipo: "input", required: true },                                                                    
                                                    { nome: "Reembolso mensalidade escolar:", tipo: "file", required: true},
                                                    { nome: "Reembolso plano de saúde:", tipo: "file", required: true},
                                                    { nome: "", tipo: "accordion suptbes", text:"Reembolso acompanhamento especializado", required: true},
                                                    { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                                                    { nome: "", tipo:"simpletext", text:"I. Mensalidade Escolar: Boleto com comprovante de pagamento; ou Recibo com timbre da escola, CNPJ, razão social e endereço devidamente assinado com a identificação do signatário."},  
                                                    { nome: "", tipo:"simpletext", text:"II. Reembolso Plano de saúde: boleto com comprovante de pagamento, ou recibo da AREC ou do Plano de Saúde."},  
                                                    { nome: "", tipo:"simpletext", text:"III. Acompanhamento especializado (Médico / Terapeuta / fisioterapeuta / Psicólogo /assistência Odontológica / Outros):"},  
                                                    { nome: "", tipo:"simpletext", text:"1) Pessoa física: Recibo original com identificação do profissional, carimbo, CPF, número do registo no respectivo conselho, informando a quantidade de sessões/consultas e os dias que foram realizados, valor unitário e valor total;"},  
                                                    { nome: "", tipo:"simpletext", text:"2) Pessoa Jurídica (Instituição / Clínica): Nota fiscal do serviço Publica da prefeitura;"},  
                                                    { nome: "", tipo:"simpletext", text:"3) Cuidador:"},  
                                                    { nome: "", tipo:"simpletext", text:".a) Carteira Assinada – Para Cadastro:CTPS com registro do contrato de trabalho e com a especificação de função de cuidador; e Cópia do CPF do profissional; e Cópia do RG do profissional; Mensalmente: Recibo de pagamento com os dados do empregado pagador e do cuidador e o mês de preferência; e Documento de arrecadação do e-social."},  
                                                    { nome: "", tipo:"simpletext", text:".b) Prestador de Serviço: Nota fiscal de serviço da prefeitura com a especificação do serviço e os dias de realização;"},  
                                                    { nome: "", tipo:"simpletext", text:"4) Medicamentos:"},  
                                                    { nome: "", tipo:"simpletext", text:"1.Cupom fiscal/nota fiscal original; e 2. prescrição médica da medicação com a data atualizada;"},  
                                                    { nome: "", tipo:"simpletext", text:"5) Fraldas descartáveis: Cupom fiscal/nota fiscal; e prescrição médica;"},  
                                                    { nome: "", tipo:"simpletext", text:"6) Alimentação Especial: Cupom fiscal/nota fiscal original; e prescrição médica;"},  
                                                    { nome: "", tipo:"simpletext", text:"7) Material Escolar:"},  
                                                    { nome: "", tipo:"simpletext", text:"a) Livros e Matérias Escolares Diversos: Cupom fiscal/nota fiscal; e lista de matérias com a identificação da série da criança, identificação da escola, carimbo e assinatura de representante da escola;"},  
                                                    { nome: "", tipo:"simpletext", text:"8) Fardamento Completo (Camisa e Calça/Bermuda): Cupom fiscal/nota fiscal discriminando o fardamento e o nome da escola;"},  
                                                    { nome: "", tipo:"simpletext", text:"9) Mochila e Lancheira: Cupom fiscal/nota fiscal original."},  
                                                    ]
                                                }


                    if (data === "Atestado Médico"){
                        return [{ project: 391, tracker: 195, project_name: "Atestado Médico" },
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Email corporativo", tipo: "input", required: true},
                            { nome: "Número da matricula", tipo: "input", required: true},
                            { nome: "Telefone", tipo: "input", required: true},
                            // { nome: "Setor", tipo: "input", required: true},
                            { nome: "Predio", tipo: "select", drop: "lotacao" },
                            // { nome: "Serviço", tipo: "select", drop: "cipa" },
                            { nome: "Data do Atestado Médico", tipo: "input", required: true },
                            { nome: "Anexar imagens", tipo: "file"},
                            { nome: "", tipo:"holder", text: "Documentação obrigatoria"},
                            { nome: "", tipo:"simpletext", text:"Atestado médico, assinado e carimbado pelo médico."},  
                            { nome: "", tipo:"simpletext", text:"Observação: A declaração falsa infringe o Código de Ética Profissional do empregado da CASAL, como também o artigo 299 do Código Penal Brasileiro sendo o empregado responsabilizado civil, penal e administrativamente caso ela ocorra."},  
                                                  
                            ]
                        }

        //GEPAT

        if (data === "Devolução de Bens"){
            return [{ project: 359, tracker: 85, project_name: "GEPAT - Devolução de Bens" }, 
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},     
                { nome: "Número da matricula", tipo: "input", required: true},       
                { nome: "Telefone", tipo: "input", required: true}, 
                { nome: "Predio", tipo: "select", drop: "predio" },                                                                
                { nome: "", tipo: "accordion gerad devolucao", text:"Reembolso acompanhamento especializado", required: true},
                { nome: "Informações complementares", tipo: "textarea" },

               
                ]
            }


            if (data === "Transferência de Bens"){
                return [{ project: 359, tracker: 85, project_name: "GEPAT - Transferência de Bens" }, 
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},     
                    { nome: "Número da matricula", tipo: "input", required: true},       
                    { nome: "Telefone", tipo: "input", required: true}, 
                    { nome: "Predio", tipo: "select", drop: "predio" },                                                                
                    { nome: "", tipo: "accordion gerad transferir", text:"Reembolso acompanhamento especializado", required: true},
                    { nome: "Informações complementares", tipo: "textarea" },
    
                   
                    ]
                }



                if (data === "Solicitar Bens Móveis"){
                    return [{ project: 359, tracker: 85, project_name: "GEPAT - Solicitar Bens Móveis" }, 
                        { nome: "Nome completo", tipo: "input", required: true},
                        { nome: "Email corporativo", tipo: "input", required: true},     
                        { nome: "Número da matricula", tipo: "input", required: true},       
                        { nome: "Telefone", tipo: "input", required: true}, 
                        { nome: "Predio", tipo: "select", drop: "predio" },                                                                
                        { nome: "", tipo: "accordion gerad solicitar", text:"Reembolso acompanhamento especializado", required: true},
                        { nome: "Informações complementares", tipo: "textarea" },
        
                       
                        ]
                    }


                    //SUPARQ
                    if (data === "Solicitar documento físico"){
                        return [{ project: 359, tracker: 99, project_name: "SUPARQ - Solicitar documento físico" }, 
                            { nome: "Nome completo", tipo: "input", required: true},
                            { nome: "Email corporativo", tipo: "input", required: true},     
                            { nome: "Número da matricula", tipo: "input", required: true},       
                            { nome: "Telefone", tipo: "input", required: true}, 
                            { nome: "Local", tipo: "input", required: true}, 
                            { nome: "Predio", tipo: "select", drop: "predio" },                                                                
                            { nome: "Documento solicitado", tipo: "input", required: true}, 
                            
                           
            
                           
                            ]
                        }


                        if (data === "Solicitar documento digitalizado"){
                            return [{ project: 359, tracker: 99, project_name: "SUPARQ - Devolução de Bens" }, 
                                { nome: "Nome completo", tipo: "input", required: true},
                                { nome: "Email corporativo", tipo: "input", required: true},     
                                { nome: "Número da matricula", tipo: "input", required: true},       
                                { nome: "Telefone", tipo: "input", required: true}, 
                                { nome: "Local", tipo: "input", required: true}, 
                                { nome: "Predio", tipo: "select", drop: "predio" },                                                                
                                { nome: "Documento solicitado", tipo: "input", required: true}, 
                                
                               
                
                               
                                ]
                            }


                            if (data === "Solicitação de arquivamento"){
                                return [{ project: 359, tracker: 95, project_name: "SUPARQ - Solicitação de arquivamento" }, 
                                    { nome: "Nome completo", tipo: "input", required: true},
                                    { nome: "Email corporativo", tipo: "input", required: true},     
                                    { nome: "Número da matricula", tipo: "input", required: true},       
                                    { nome: "Telefone", tipo: "input", required: true}, 
                                    { nome: "Local", tipo: "input", required: true}, 
                                    { nome: "Predio", tipo: "select", drop: "predio" },                                                                
                                    { nome: "Documento solicitado", tipo: "input", required: true}, 
                                    
                                   
                    
                                   
                                    ]
                                }


    //CIPA

    
    if (data === "Cipa"){
        return [{ project: 393, tracker: 85, project_name: "Cipa" },
            { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Setor", tipo: "input", required: true},
            { nome: "Predio", tipo: "select", drop: "predio" },
            { nome: "Serviço", tipo: "select", drop: "cipa" },
            { nome: "Descrição", tipo: "textarea" },
            
            ]
        }



//SUDEO 
        if (data === "Solicitar Acesso SEI"){
            return [{ project: 293, tracker: 85, project_name: "SEI - Solicitar Acesso" },
                { nome: "Nome completo", tipo: "input", required: true},
                { nome: "Email corporativo", tipo: "input", required: true},
                { nome: "Número da matricula", tipo: "input", required: true},
                { nome: "Telefone", tipo: "input", required: true},
                { nome: "Setor", tipo: "input", required: true},
                { nome: "Predio", tipo: "select", drop: "predio" },
                { nome: "Serviço", tipo: "select", drop: "Servico" },
                { nome: "Solicitação / Observação: Serviços que serão executados pelo funcionário", tipo: "textarea" },
                
                ]
            }

            if (data === "Solicitar Suporte SEI"){
                return [{ project: 293, tracker: 7, project_name: "SEI - Solicitar Suporte" },
                    { nome: "Nome completo", tipo: "input", required: true},
                    { nome: "Email corporativo", tipo: "input", required: true},
                    { nome: "Número da matricula", tipo: "input", required: true},
                    { nome: "Telefone", tipo: "input", required: true},
                    { nome: "Setor", tipo: "input", required: true},
                    { nome: "Predio", tipo: "select", drop: "predio" },                    
                    { nome: "Descrição", tipo: "textarea" },
                    
                    ]
                }



    //SULOS
    // Toca de Responsavel


    if (data === "Troca de responsavel"){
        return [{ project: 12, tracker: 3, project_name: "Troca de responsavel" }, { nome: "Nome completo", tipo: "input", required: true},
            { nome: "Email corporativo", tipo: "input", required: true},
            { nome: "Número da matricula", tipo: "input", required: true},
            { nome: "Telefone", tipo: "input", required: true},
            { nome: "Setor", tipo: "input", required: true},
            { nome: "Predio", tipo: "select", drop: "predio" },
            { nome: "Tombamento do equipamento", tipo: "input", required: true},
            { nome: "Descrição", tipo: "textarea" },
            
            ]
        }


    if (data === "Ivan Teste" ){
        return [{ project: 12, tracker: 3, project_name: "Computador não funciona" },
            {nome: "TEste", tipo: "textarea", required: false},
            {nome: "Sistema Relacionado", tipo:"select", required: true, drop: "hcm"}


        ]
    }


    

}
