import React from "react";

function Divisionbar() {
 

  return (
 
     <hr style={{width: "80%", margintop: "25px"}}/>
  
  );
}

export default Divisionbar;
