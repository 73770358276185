import React, { useEffect } from "react";
import Supmer from "./supmer/Supmer";
import Supmast from "./supmast/Supmast";
import MainLayout from "../common/Layout";
import Rodape from "../common/Rodape";


function Getin() {

  

   return (

      <div>
         <MainLayout></MainLayout>
         <Supmer></Supmer>
         <Supmast></Supmast>


      </div>

   );
}

export default Getin;
