import React from "react";
import Divisionbar from "../common/Divisionbar";
import MainLayout from "../common/Layout";
import Rodape from "../common/Rodape";
import Gedep from "./gedep/Gedep";
import Suptbes from "./gedep/Suptbes";
import Gesmet from "./gedep/gesmet/Gesmet";


function Rh ()
{
    return(
        <div>
            <MainLayout></MainLayout>
            <div className="main">
            <Gedep/>
            <Suptbes></Suptbes>
            <Gesmet></Gesmet>
            </div>
        </div>
    )
}

export default Rh;
