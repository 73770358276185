import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import './tester.css'
import logo from '../img/logocasal60.png';

const Testerte = () => {
  const [elements, setElements] = useState([]);
  const [cpf, setCpf] = useState('');
  const navigate = useNavigate();
  let data = useParams();
  
  const currentapiurl = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    const initialize = async () => {
      try {
        await AsyncStorage.removeItem('@Userchamados');
        const userReceived = data.data;

        // If there's any processing to be done with userReceived, do it here

      } catch (error) {
        console.log(error);
        setElements([
          <h1
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '2.5em',
              height: '1em',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
              margin: 'auto',
              textAlign: 'center',
              opacity: 0,
              animation: 'appear 2s 2.5s forwards infinite',
            }}
          >
            USUARIO NÃO AUTENTICADO <br />
            <button onClick={() => (window.location.href = 'https://intranet.casal.al.gov.br')}>
              Logar na Intranet
            </button>
          </h1>,
        ]);
      }
    };

    initialize();
  }, [data]);

  const login = async (e) => {
    e.preventDefault();
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;

    try {
      await axios.post(`${currentapiurl}/api/v1/teste`, { cad: parseInt(username), cpf: password }).then(async (e) => {
        if (e.data.message === 'not found' || e.data.message === undefined) {
          alert('Usuario invalido');
        } else {
          await AsyncStorage.setItem('@Userchamados', JSON.stringify(e.data.message)).then(() => {
            navigate('/');
          });
        }
      });
    } catch (error) {
      alert('Usuario invalido');
    }
  };

  const handleCpfChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    if (value.length > 11) {
      value = value.substring(0, 11); // Ensure value does not exceed 11 digits
    }

    // Apply CPF mask
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    setCpf(value);
  };

  const imgstyle = {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90px',
    backgroundPosition: 'center center',
    objectFit: 'cover',
    backgroundColor: 'rgba(0,0,0,0)',
    width: '95px',
    height: '95px',
  };

  const formContainerStyle = {
    marginTop: '-30px',
  };

  const formLoginStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'black',
    borderWidth: 'thick',
  };

  const submitButtonStyle = {
    marginTop: '20px',
    borderColor: 'black',
    borderStyle: 'solid',
  };

  const bodyStyle = {
    overflow: 'hidden',
    background: 'rgb(252, 252, 252)',
  };

  const dropContainerStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    height: '200px',
    width: '200px',
  };

  const h1Style = {
    color: 'white',
    position: 'absolute',
    fontSize: '2.5em',
    height: '1em',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    margin: 'auto',
    textAlign: 'center',
    opacity: 0,
    animation: 'appear 2s 2.5s forwards infinite',
  };

  return (
    <div style={bodyStyle}>
      {elements}
      <div className="drop-container" style={dropContainerStyle}>
        <form style={formContainerStyle} onSubmit={login}>
          <div className="formulariologin" style={formLoginStyle}>
            <div style={imgstyle}></div>
            <label htmlFor="username" style={{ marginTop: '20px' }}>Matricula:</label>
            <input type="number" id="username" name="username" className='inserir' required />
            <label htmlFor="password">CPF:</label>
            <input type="type" name="password" className='inserir' value={cpf} onChange={handleCpfChange} required />
          </div>
          <div style={buttonContainerStyle}>
            <button type="submit" className="btsm" style={submitButtonStyle}>Entrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Testerte;
