import React from "react";
import Divisionbar from "../common/Divisionbar";
import MainLayout from "../common/Layout";
import Rodape from "../common/Rodape";
import Abertura from "./abertura_geroc/Abertura";


function Geroc ()
{
    return(
        <div>
            <MainLayout></MainLayout>
            <div className="main">
            <Abertura/>
            </div>
        </div>
    )
}

export default Geroc;
