import React, {useEffect, useState} from "react";
import MainLayout from "../common/Layout";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Rodape from "../common/Rodape";
import { Selector } from "../common/Formfilter";
import Divisionbar from "../common/Divisionbar";
import { useNavigate } from "react-router-dom";
import './Sudeo.css'

function Sudeo() {
   
    useEffect(()=>{
       const cleaner = async ()=> {
       await AsyncStorage.removeItem('@formulario')
       }
 
 
       cleaner()
    }, [])


    const [sei, setSei] = useState(false);

    const handleMouseEntersei = (data) => {
        setSei(true);
    };

    const handleMouseLeavesei = () => {
        setSei(false);
    };

    const Navegacione = useNavigate ()

    const formselector = async (data) =>{
        let a = Selector(data.target.innerText)

        await AsyncStorage.setItem("@formulario", JSON.stringify(a)).then(()=>{
            Navegacione("/FORMS"); })
            .catch((err)=> console.log(err))
    }

   return (

      <div>
         <MainLayout></MainLayout>
         <div className="main">
            <h1>SUDEO</h1>
            <Divisionbar/>
            <div className="buttons">
            <div className="menu" onMouseEnter={handleMouseEntersei} onMouseLeave={handleMouseLeavesei}>
                <button className={`sudeo-toggle ${sei ? 'open' : ''}`} ></button>
                <label>Sei</label>
                <ul className={`main-menu ${sei ? 'open' : ''}`}>
                    <li><button onClick={formselector}>Solicitar Acesso SEI</button></li>
                    <li><button onClick={formselector}>Treinamento</button></li>
                    <li><button onClick={formselector}>Solicitar Suporte SEI</button></li>
                    <li><button onClick={() => { window.open("https://mudarsenha.sei.al.gov.br/login/?next=/") }}>Alterar senha</button></li>
                </ul>


            </div>
            </div>

         </div>

      </div>
   
   );
 }
 
 export default Sudeo;
 
